
.dadJokesHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #264653;
    width: 100%;
    height: 96px;
    
}

.headerH1 {
    font-family: 'Pacifico', cursive;
    color: #FFF;    
    font-size: 40px;
}