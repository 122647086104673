/* Joke Box  */

.jokeContainer {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.jokeBox {
  position: relative;
  align-self: center;
  height: 156px;
  width: 80vw;
  max-width: 400px;
  background-color: #2a9d8f;
  border-radius: 8px;
}

#jokeDrop {
  font-size: 16px;
  height: 156px;
}
@media (min-width: 640px) {
  .randomJoke {
    padding-left: 16px;
    padding-right: 16px;
  }
  #jokeDrop {
    width: 400px;
  }
}

.randomJoke {
  width: 96%;
  padding: 16px;
  color: #fff;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.randomJokeBlur {
  filter: blur(4px);
}

.viewJoke {
  position: absolute;
  bottom: -32px;
  left: -8px;
  right: -8px;
  margin: 0 auto;
  background-color: #e76f51;
  background-image: url(../../images/eye-regular\ 1.png);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  height: 64px;
  width: 64px;
  box-shadow: 0px 0px 8px rgba(38, 70, 83, 0.5);
}

.viewJoke:hover {
  background-color: #f4a360;
}

/* Score content  */
@media (min-width: 640px) {
  .scoreContent {
    width: 600px;
    margin: 0 auto;
  }
}

/* Player and Laugh Count  */

.playerLaughCount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-top: 72px;
}

.playerLaughCountText {
  color: #264653;
  font-size: 24px;
  font-family: "Pacifico", cursive;
}

/* ScoreBoard  */

.scoreBoard {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
}

.playerName {
  color: #2a9d8f;
  font-size: 24px;
  font-family: "Quicksand", sans-serif;
  display: flex;
  flex: 1;
  align-items: center;
}

.scoreBoardCount {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}



.laughNumber {
  color: #2a9d8f;
  font-size: 24px;
  font-family: "Quicksand", sans-serif;
  width: 32px;
  /* flex: .6; */
  /* flex-basis: 0; */
}
