
label {
  font-family: "Pacifico", cursive;
  font-size: 18px;
  color: #264653;
  text-align: left;
  display: block;
  margin-bottom: 16px;
}

/* Global Styles */
h1 {
  color: #fff;
  font-size: 40px;
  font-family: "Pacifico", cursive;
}

p,input {
  font-size: 24px;
  font-family: "Quicksand", sans-serif;
  color: #264653;
}

.pacificoBlueH2 {
  color: #264653;
  font-size: 32px;
  font-family: "Pacifico", cursive;
}

h3 {
  color: #fff;
  font-size: 24px;
  font-family: "Pacifico", cursive;
}

#whiteText {
  color: #fff;
}

.bottomLink {
  margin-top: 24px;
  font-family: "Quicksand", sans-serif;
  margin-top: 24px;
}

.inputText {
  color: #2a9d8f;
  font-size: 24px;
  font-family: "Quicksand", sans-serif;
  border-radius: 6px;
}

/* Page layout  */

.pageLayoutContainer {
  text-align: center;
}

.pageContent {
  padding: 0 24px;
}

.pageHeading {
  padding-top: 64px;
  margin-bottom: 64px;
}
.pageHeading p {
  margin-top: 16px;
}

.inputContainer {
  margin-top: 24px;
}

input {
  height: 64px;
  width: 100%;
  padding: 0 16px;
  border: 2px solid #2a9d8f;
  font-size: 24px;
}

.inputNumber {
  display: flex;
  width: 100%;
  align-items: center;
}

.inputNumber input {
  text-align: center;
}

@media (min-width: 640px) {
  .pageLayoutContainer {
    padding-bottom: 0px;
  }
}

/* Desktop  */
.desktopMaxWidth {
  max-width: 64rem;
  margin: 0 auto;
}

@media (min-width: 640px) {
  .inputContainer {
    max-width: 32rem;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Name Grid  */

/* Gradient Background  */

/* Background */
.gradientBack {
  background-image: linear-gradient(#264653, #2a9d8f, #f4a261);
  background-color: #000;
  text-align: center;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

/* Buttons */
.orangeBtn {
  background-color: #e76f51;
  border-radius: 8px;
  border-style: none;
  font-family: "Pacifico", cursive;
  font-size: 24px;
  padding: 8px 16px;
  width: 100%;

  color: #ffffff;
  text-decoration: none;
}
.orangeBtn:hover {
  background-color: #f4a360;
  cursor: pointer;
}

.bottomButtons {
  width: 100%;
  bottom: 0px;
  left: 0;
  right: 0;
  padding: 32px 24px;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 1) 100%,
    rgba(255, 255, 255, 1) 100%
  );
}

@media (min-width: 640px) {
  .bottomButtons {
    position: relative;
    /* margin-top: 64px; */
  }

  .orangeBtn {
    max-width: 320px;
  }

  .bottomButtons {
    padding: 48px 32px;
  }
}

/* Inputs  */

.playerAndRoundsP {
  font-family: "Quicksand", sans-serif;
  margin: 24px 0px 0px;
  color: #264653;
}

.playerP {
  font-family: "Pacifico", cursive;
  font-size: 18px;
  color: #264653;
}

.playerP {
  text-align: left;
  margin-top: 24px;
}

.playerInput:focus {
  outline: #f4a261;
  border-color: #f4a261;
  /* box-shadow: 0 0 3px #000; */
}

/* Orange Banner  */
.orangeBanner {
  text-align: center;
  height: 56px;
  line-height: 56px;
  background-color: #f4a261;
  vertical-align: middle;
}
