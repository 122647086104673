/* Trophy  */
.trophyIcon {
    background-image: url('../../images/trophy.png');
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    height: 49.78px;
    width: 56px;
    margin-top: 64px;
}
/* Scoreboard Grid  */

#whiteP {
    color: #fff;
}

.scoreBoard {
  max-width: 600px;
  margin: 0 auto;
}