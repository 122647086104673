.introContents {
    display: flex;
    flex-direction: column;
    padding: 0px 24px 0px 24px;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.dadJokesIntroH1 {
    color: #fff;
	font-size: 48px;
    font-family: 'Pacifico', cursive;
}

.introH2 {
    color: #FFFFFF;
    font-size: 24px;
    font-family: 'Quicksand', sans-serif;
    margin-top: 24px;
}

#removeGradient {
    background-image: none;
}
